import * as React from 'react'
import { v4 as uuidv4 } from 'uuid'
import { PageProps, graphql } from 'gatsby'
import styled from 'styled-components'

import LayoutBasic from 'layouts/LayoutBasic'
import Seo from 'components/atoms/Seo'
import FindDealer from 'components/organisms/FindDealer'

import type { DealerPoint } from 'types/domain'
import media from 'styles/media'

const PageFindDealer = styled.div`
  margin: 112px 0;

  ${media.lg.max} {
    margin: 64px 0;
  }
`

const PageDealers: React.FC<PageProps<Queries.PageDealersQuery>> = ({
  data,
}) => {
  const PAGE = data?.wpPage?.PageDealers!
  const SEO = data?.wpPage?.seo!

  if (!PAGE || !SEO) throw new Error("CMS data didn't load properly")

  const SERVICE_POINTS: DealerPoint[] =
    PAGE?.dealersList
      ?.filter(
        (dealer) => dealer?.dealersListName && dealer?.dealersListAddress
      )
      ?.map((dealer) => ({
        name: dealer?.dealersListName!,
        address: dealer?.dealersListAddress!,
        openingHours: dealer?.dealersListHoursopen!,
        phoneNumber: dealer?.dealersListPhone!,
        routeLink: dealer?.dealersListGooglemap!,
        latitude: Number(dealer?.dealersListAddressLat)!,
        longitude: Number(dealer?.dealersListAddressLng)!,
        isShowroom: Boolean(dealer?.dealersListShowroom),
        isServicePoint: Boolean(dealer?.dealersListService),
        id: uuidv4(),
        postCode: dealer?.dealersListZipcode!,
        city: dealer?.dealersListCity!,
      })) || []

  return (
    <LayoutBasic>
      <Seo
        title={SEO?.title!}
        description={SEO?.metaDesc!}
        ogTitle={SEO?.opengraphTitle!}
        ogDescription={SEO?.opengraphDescription!}
        ogImage={SEO?.opengraphImage?.sourceUrl!}
        twitterTitle={SEO?.twitterTitle}
        twitterDescription={SEO?.twitterDescription}
        twitterImage={SEO?.twitterImage?.sourceUrl}
      />
      <PageFindDealer>
        <FindDealer points={SERVICE_POINTS} page="find-dealer" />
      </PageFindDealer>
    </LayoutBasic>
  )
}

export default PageDealers

export const query = graphql`
  query PageDealers {
    wpPage(slug: { regex: "/dealerzy/" }) {
      PageDealers {
        dealersList {
          dealersListName
          dealersListZipcode
          dealersListShowroom
          dealersListServiceMobile
          dealersListService
          dealersListPhone
          dealersListName
          dealersListHoursopen
          dealersListGooglemap
          dealersListCity
          dealersListAddressLng
          dealersListAddressLat
          dealersListAddress
        }
      }
      seo {
        title
        title
        metaDesc
        opengraphDescription
        opengraphTitle
        opengraphImage {
          sourceUrl
        }
        twitterTitle
        twitterDescription
        twitterImage {
          sourceUrl
        }
      }
    }
  }
`
